// $color-bg: rgb(16, 16, 16);
// $color-bg: #03031B;
$color-bg: white;
$color-text-first: rgb(255, 255, 255);
$color-text-second: rgb(203, 203, 203);
$color-text-third: rgb(184, 186, 186);
$color-text-fourth: rgb(151, 151, 151);

// $color-green: rgb(0, 196, 117);
$color-green: #63C796;

$mobile: 480px;
$tablet: 1077px;
$desktop: 1024px;
$large: 1440px;

$sidebar-width: 80px;
$sidebar-fullwidth: 300px;
