@import "variables";
@import "mixin";

.main {
    .mobileNav {
        position: fixed;
        width: 100vw;
        padding: 20px 20px 10px 20px;
        display: none;
        background-color: rgba($color-bg, 0.9);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        z-index: 15;

        @media screen and (max-width: 570px) {
            @include center;
            justify-content: space-between;
        }

        .logoContainer {
            a {
                text-decoration: none;
                background: linear-gradient(to bottom right, #FEB765, #B849E2, #8879FA);
                -webkit-background-clip: text;
                -moz-background-clip: text;
                background-clip: text;
                color: transparent;
            }

            .logoLink {
                display: flex;
                align-items: center;
                justify-content: center;

                .logoImage {
                    height: 30px;
                    width: auto;
                    filter: invert(1);
                }
            }
        }

        .mobileMenu {
            background-color: transparent;
            color: black;
            font-size: 20px;
            outline: none;
            border: none;
            cursor: pointer;

            img {
                width: 20px;
                // filter: invert(1);
            }
        }
    }

    .mobileOpenOverlay {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: #000000;
        z-index: 19;
        background-color: rgba($color: #000000, $alpha: 0.5);
        opacity: 0;
        transition-duration: 0.5s;
        pointer-events: none;

        @media screen and (max-width: 570px) {
            &.mobileOpen {
                opacity: 1;
                transition-duration: 0.5s;
                pointer-events: all;
            }
        }
    }

    .sidebar {
        position: fixed;
        width: $sidebar-width;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-block-start: 20px;
        padding-block-end: 60px;

        background-color: rgba($color: #000000, $alpha: 0.8);
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);

        transition: width 0.2s ease-in-out;
        z-index: 20;
        user-select: none;

        @supports (-webkit-touch-callout: none) {
            background-color: rgba($color: #000000, $alpha: 1);
        }

        @media screen and (max-width: 570px) {
            // opacity: 0;
            overflow: hidden;
            width: 0px;

            &.mobileOpen {
                opacity: 1;
                width: 80vw;
                transition: width 0.2s ease-in-out;

                .firstRow,
                .secondRow {
                    .sideContentWrapper {

                        .logoText,
                        span {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }

                .secondRow {
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    gap: 0px;

                    .sideContentWrapper {
                        padding: 10px 10px;

                        .logoText,
                        span {
                            display: none;
                        }
                    }
                }
            }
        }

        @media screen and (min-width: 570px) {
            &:hover {
                width: $sidebar-fullwidth;

                @media screen and (max-width: $tablet) {
                    width: $sidebar-width;
                }

                .firstRow,
                .secondRow {
                    .sideContentWrapper {

                        .logoText,
                        span {
                            visibility: visible;
                            opacity: 1;

                            @media screen and (max-width: $tablet) {
                                visibility: hidden;
                            }
                        }
                    }
                }

                .firstRow {
                    .sideContentWrapper {
                        &:not(:first-child) {
                            &:hover {
                                background-color: #63c7961a;
                            }
                        }
                    }
                }

                .secondRow {
                    .sideContentWrapper {
                        &:hover {
                            background-color: #63c7961a;
                        }
                    }
                }
            }
        }

        .firstRow,
        .secondRow {
            display: flex;
            flex-direction: column;

            .active {
                background-color: #ffffff33;

                img {
                    color: #63c7961a;
                }
            }

            .logoWrapper {
                margin-bottom: 20px;
            }

            .comingSoon {
                cursor: default;
                pointer-events: none;
                opacity: 0.3;
            }

            .sideContentWrapper {
                padding-inline: 30px;
                box-sizing: border-box;
                padding: 10px 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 10px;
                transition: background-color 0.2s ease-in-out;
                cursor: pointer;
                color: white;

                img {
                    user-select: none;
                }

                .logoText {
                    width: auto;
                    height: 24px;
                }

                .logoText,
                span {
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0s, opacity 0.2s linear;
                    text-wrap: nowrap;
                }
            }
        }

        .secondRow {
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 570px) {
                margin-bottom: 50px;
            }
        }

        .sideIcon {
            position: relative;
            width: 40px;
            height: 40px;
        }
    }

}