@import "../variables";

.main {
    margin-left: $sidebar-width;

    @media screen and (max-width:570px) {
        margin-left: 0;
        padding-top: 40px;
    }
}

.leftBg {
    position: absolute;
    left: 0;
    top: 10vh;
    height: 90vh;
    width: auto;
    z-index: -1;
    opacity: 0.5;

    @media screen and (max-width: 840px) {
        display: none;
    }
}

.rightBg {
    position: absolute;
    right: 0;
    top: 0;
    height: 90vh;
    width: auto;
    z-index: -1;
    opacity: 0.5;

    @media screen and (max-width: 840px) {
        display: none;
    }
}

.rightBgMobile {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 90vh;
    width: auto;
    z-index: -1;
    opacity: 0.5;

    @media screen and (max-width: 840px) {
        display: block;
    }
}

.routes {
    max-width: $large;
    margin-inline: auto;

    padding: 40px 60px;

    @media screen and (max-width: 1200px) {
        padding: 40px 20px;
    }

    @media screen and (max-width: $tablet) {
        padding: 40px 20px;
    }

    @media screen and (max-width: $mobile) {
        padding: 40px 10px;
    }
}