@import "../mixin";
@import "../variables";
@import "../animations";

.form {
    @include center(column);
    padding: 0px 20px;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .input__box {
        width: 100%;
        @include center(column);
        gap: 10px;
        // margin-bottom: 10px;
        padding: 5px 5px;
        border-radius: 16px;
        // background-color: rgba(#000000, $alpha: 1);

        img {
            height: 60px;
            width: auto;
        }

        .label {
            min-width: 100%;
            display: flex;
            align-items: center;
            justify-content: left;
            font-size: 20px;
            // margin-bottom: 10px;

            background: linear-gradient(to bottom right, #FEB765, #B849E2, #8879FA);
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;
            color: transparent;
        }

        input {
            padding: 20px;
            width: 100%;
            background-color: rgba(#000000, $alpha: 0.1);
            color: $color-text-fourth;
            font-size: 16px;
            border-radius: 16px;
            border: none;
            outline: none;

            &::placeholder {
                color: $color-text-fourth;
            }

            &:focus {
                background-color: rgba($color: #000, $alpha: 0.15);
            }

            &:disabled {
                color: $color-text-fourth;
            }
        }

        &.invalidAddress {
            border: 1px solid red;
            animation: vibrate 0.3s linear;
            animation-iteration-count: 1;
        }
    }

    .voteContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        gap: 15px;
        width: 100%;
        margin-top: 15px;

        .voteSelect {
            width: 180px;
        }

        .dropdownOption {
            display: flex;
            align-items: center;
            justify-content: left;
            gap: 15px;

            img {
                width: 30px;
                height: auto;
            }
        }
    }

    .actionContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        margin-top: 30px;

        .submit__button {
            padding: 14px;
            // background-color: rgba(#000000, $alpha: 0.1);
            background: linear-gradient(to bottom right, #FEB765, #B849E2, #8879FA);
            color: white;
            text-transform: uppercase;
            font-weight: 600;
            border-radius: 20px;
            border: none;
            outline: none;
            width: 100%;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:disabled {
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.5;
            }

            &:hover {
                background-color: rgba(#000000, $alpha: 0.2);
            }
        }
    }

    .secondary__button {
        margin-top: 10px;
        padding: 5px 20px;
        // background-color: rgba(#000000, $alpha: 0.1);
        border: 1px solid rgba(#000000, $alpha: 0.2);
        background: linear-gradient(to bottom right, #FEB765, #B849E2, #8879FA);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        color: transparent;
        text-transform: uppercase;
        font-weight: 600;
        border-radius: 20px;
        // border: none;
        outline: none;
        // width: 100%;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:disabled {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
        }

        &:hover {
            // background-color: rgba(#000000, $alpha: 0.2);
            padding: 5px 30px;
        }
    }


    @media screen and (max-width: 570px) {
        .input__box {
            margin-bottom: 20px;

            img {
                height: 40px;
                width: auto;
            }

            input {
                padding: 15px 10px;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 20px 10px;


        .input__box {
            padding: 10px;

            label {
                display: none;
            }

            input {
                font-size: 14px;
            }
        }
    }
}

.success {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    color: #71f176;
    font-size: xx-large;

    iframe {
        border: none;
        min-width: 100%;
        max-width: 500px;
        height: auto;
    }
}