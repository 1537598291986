@import "mixin";
@import "variables";

.faq {
    margin: 15px;
    padding: 15px;
    background: #00000013;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    color: black;

    .faqQuestion {
        position: relative;
        font-size: 20px;
        padding-right: 80px;
        transition: all 0.4s ease;

        &::after {
            content: "+";
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            transition: all 0.2s ease;
        }
    }

    .faqAnswer {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: all 0.2s ease;
        color: rgba(0, 0, 0, 0.7);
    }

    &.open {
        .faqQuestion {
            margin-bottom: 15px;

            &::after {
                content: "-";
            }
        }

        .faqAnswer {
            max-height: 1000px;
            opacity: 1;
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}