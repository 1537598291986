@import "../mixin";
@import "../variables";
@import "../animations";

.airdrop {
    @include center(column);
    justify-content: center;
    align-items: center;
    margin: 0px auto 0;
    // gap: 20px;
    margin-left: $sidebar-width;

    .faqs {
        width: 100%;
        max-width: 768px;
        margin: 0 auto;
        padding: 0px 15px;
    }

    @media screen and (max-width:570px) {
        padding-top: 0px;
        margin-left: 0;
    }

    .form__bg_1 {
        padding: 10px;
        border-radius: 16px;
        z-index: 1;
        position: relative;

        &::before {
            content: "";
            border-radius: 16px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(10px);
            z-index: -1;
        }

        // background: rgba(255, 255, 255, 0.01);
        // background: -moz-linear-gradient(
        //     180deg,
        //     rgba(255, 255, 255, 0.07) 0%,
        //     rgba(255, 255, 255, 0.02) 90%,
        //     rgba(0, 0, 0, 0) 100%
        // );
        // background: -webkit-linear-gradient(
        //     180deg,
        //     rgba(255, 255, 255, 0.07) 0%,
        //     rgba(255, 255, 255, 0.02) 90%,
        //     rgba(0, 0, 0, 0) 100%
        // );
        // background: linear-gradient(
        //     180deg,
        //     rgba(255, 255, 255, 0.07) 0%,
        //     rgba(255, 255, 255, 0.02) 90%,
        //     rgba(0, 0, 0, 0) 100%
        // );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000", GradientType=1);
    }

    .form__bg_2 {
        padding: 10px;
        border-radius: 16px;
        // background: rgba(255, 255, 255, 0.03);
        // background: -moz-linear-gradient(
        //     180deg,
        //     rgba(255, 255, 255, 0.07) 0%,
        //     rgba(255, 255, 255, 0.02) 90%,
        //     rgba(0, 0, 0, 0) 100%
        // );
        // background: -webkit-linear-gradient(
        //     180deg,
        //     rgba(255, 255, 255, 0.07) 0%,
        //     rgba(255, 255, 255, 0.02) 90%,
        //     rgba(0, 0, 0, 0) 100%
        // );
        // background: linear-gradient(
        //     180deg,
        //     rgba(255, 255, 255, 0.07) 0%,
        //     rgba(255, 255, 255, 0.02) 90%,
        //     rgba(0, 0, 0, 0) 100%
        // );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000", GradientType=1);
    }

    .form__bg_3 {
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.475);
        background: -moz-linear-gradient(180deg,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(255, 255, 255, 0.1) 90%,
                rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(180deg,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(255, 255, 255, 0.1) 90%,
                rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(180deg,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(255, 255, 255, 0.1) 90%,
                rgba(0, 0, 0, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000", GradientType=1);
    }

    .form {
        flex: 0.5;
        min-width: 400px;
        margin: 0;
        width: 100%;
        max-width: 600px;
    }

    @media screen and (max-width: $tablet) {
        flex-direction: column;
        align-items: center;

        .form {
            margin: 0;
            width: 100%;
            max-width: 600px;
        }
    }

    @media screen and (max-width: $mobile) {
        margin: 20px auto 0;

        .form__bg_1 {
            padding: 5px;
        }

        .form__bg_2 {
            padding: 5px;
        }

        .form {
            min-width: 0;
        }
    }
}