.main {
    text-align: center;

    .toggleSwitch {
        position: relative;
        width: 137px;
        display: inline-block;
        text-align: left;
        top: 8px;

        .checkbox {
            display: none;

            &:checked {
                +.label {
                    .toggleInner {
                        margin-left: 0;
                    }

                    .switch {
                        right: 0px;

                        &::before {
                            content: "Update";
                        }
                    }
                }
            }

            &:not(:checked) {
                +.label {
                    .switch {
                        &::before {
                            content: "New";
                        }
                    }
                }
            }
        }

        .label {
            display: block;
            overflow: hidden;
            cursor: pointer;
            border-radius: 8px;
            font-size: 14px;
        }

        .toggleInner {
            display: block;
            width: 200%;
            margin-left: -100%;
            transition: margin 0.3s ease-in 0s;

            &:before,
            &:after {
                float: left;
                width: 50%;
                height: 37px;
                padding: 0;
                line-height: 36px;
                color: #fff;
                font-weight: bold;
                box-sizing: border-box;
                font-weight: 200;
            }

            &:before {
                content: "New";
                padding-left: 10px;
                background-color: #161E31;
                color: #fff;
            }

            &:after {
                content: "Update";
                padding-right: 10px;
                background-color: #161E31;
                color: #fff;
                text-align: right;
            }
        }

        .switch {
            display: flex;
            width: 50%;
            background: linear-gradient(to bottom right, #FEB765, #B849E2, #8879FA);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 70px;
            border-radius: 8px;
            transition: all 0.3s ease-in 0s;
            text-align: center;
            align-items: center;
            justify-content: center;

            &::before {
                content: "New";
            }
        }
    }
}